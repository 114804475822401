<template>


  <div class="header header--big">

    <div class="container container--big">
      <div class="content-block ">
          <div class="content-block__media media ratio ratio--big-bg">
              <div class="media__wrapper"  v-if="$t('home.header.image') != 'home.header.image'">
                <img :src="$t('home.header.image')" alt="" class="media__source img-fit">
              </div>
          </div>
      </div>
    </div>


    <div class="header__body">
      <div class="container">
          <div class="row">
              <div class="col-sm-9 col-md-8 col-lg-6 col-xl-5">
                  <div class="header__content">
                      <div class="content-block--square-alt">
                          <div class="content-block__background ratio bg-secondary d-flex align-items-center">
                              <div class="text mb-lg-0">
                                  <h1 class="header__title h2 mb-2" v-t="'home.header.title'"></h1>
                                  <div class="header__subtitle text-xl" v-t="'home.header.subtitle'"></div>

                                  <div class="header__actions actions mt-5">
                                    <div class="btn-group d-flex flex-wrap">
                                        <a :href="$t('home.header.button_url')" class="btn btn-primary" title="">
                                            <span v-t="'home.header.button_label'"></span>
                                            <i class="icon icon-keyboard_arrow_right"></i>
                                        </a>
                                    </div>
                                  </div>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
  components: {},
  props: [],
  created() {}
};
</script>

<style lang="css" scoped></style>
